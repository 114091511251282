import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function House (props) {
  return (
    <Picto
      viewbox="0 0 14.1 15"
      d="M13.5 6.3L7.5.6C7.4.4 7.2.3 7 .3s-.4.1-.5.2L4.3 2.7v-.3c0-.4-.3-.7-.7-.7h-.9c-.4-.1-.8.3-.8.7V5L.6 6.3c-.2.2-.2.4-.2.6 0 .4.3.7.7.7h.8v6.2c0 .4.3.7.7.7h8.8c.4 0 .7-.3.7-.7V7.6h.9c.4 0 .7-.3.7-.7 0-.2-.1-.4-.2-.6z"
      {...props}
    />
  )
}

House.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
