import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import { formatPriceAnnually, formatPriceMonthly } from "../../utils/format"
import { colors, condition, fontSizes, modules } from "../structure/theme"
import { usePrice, ANIMATION_TIME } from "./product.utils"
import { useTranslation } from "../translation/translate.component"
import { usePageContext } from "../navigation/pageContext.provider"
import { localeToLang } from "../translation/translate.utils"

const PriceWrapperAnimated = styled.div`
  line-height: 0;
  display: inline-block;
  font-size: ${props => (props.animation ? fontSizes(`rightVariant`, `xx_large`, `xxx_large`) : 0)};
  transition: font-size ${ANIMATION_TIME}s;
`

const StandardPriceWrapperAnimated = styled.div`
  display: inline-block;
  width: auto;
  margin: ${({ cardVariant, rightVariant }) => (cardVariant ? (rightVariant ? `0 0 0 auto` : `auto`) : `0 auto 0 0`)};
  font-size: ${props => (props.animation ? fontSizes(`regular`) : fontSizes(`rightVariant`, `xx_large`, `xxx_large`))};
  line-height: ${props =>
    props.animation ? fontSizes(`regular`) : fontSizes(`rightVariant`, `xx_large`, `xxx_large`)};
  transition: font-size ${ANIMATION_TIME}s;

  ${({ rowVariant }) =>
    rowVariant &&
    `
    margin-left: 6px;
    font-size: ${fontSizes(`large`)};
    font-weight: 500;
  `}
`

const AnimatedBold = styled.span`
  padding: 0 2px;
  position: relative;
  font-weight: 900;
  transition: font-weight ${ANIMATION_TIME}s;

  &:after {
    position: absolute;
    display: ${condition(`animation`, `block`, `none`)};
    content: "";
    left: 0;
    top: 40%;
    right: 0;
    border-top: 2px solid;
    border-color: ${props =>
      props.$color || (props.cardVariant ? modules(`product.card.price.color`) : colors(`primary`))};
    transform: rotate(-20deg) translate(-1px, 0);
    opacity: ${condition(`animation`, `1`, `0`)};
    transition: opacity ${ANIMATION_TIME * 2}s;
  }

  ${({ rowVariant }) =>
    rowVariant &&
    `
    font-size: ${fontSizes(`large`)};
    font-weight: 500;

    &:after {
      border-top: 1px solid;
    }
  `}
`

const Container = styled.div`
  color: ${props => props.color || (props.cardVariant ? modules(`product.card.price.color`) : colors(`primary`))};
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${condition(`cardVariant`, `center`, `left`)};
  margin: 8px 0px 8px 0px;

  ${({ rowVariant }) =>
    rowVariant &&
    `
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
  `}
`

const PerMonth = styled.span`
  /* stylelint-disable */
  font-size: 0.5em;
  line-height: 1.3em;
  font-weight: 300;
  /* stylelint-enable */

  ${({ rowVariant }) =>
    rowVariant &&
    `
    font-size: ${fontSizes(`large`)};
    font-weight: 500;
  `}
`

const Bold = styled.span`
  /* stylelint-disable */
  line-height: 1em;
  /* stylelint-enable */
  font-weight: ${props => props.fontWeight || 900};
`

const ContainerFlex = styled.div`
  display: flex;
  align-items: baseline;
`

const Instead = styled.span`
  font-size: ${condition(`animation`, `0.8em`, `0em`)};
  /* stylelint-disable */
  line-height: 1em;
  /* stylelint-enable */
  transition: font-size ${ANIMATION_TIME}s;

  ${({ rowVariant }) =>
    rowVariant &&
    `
    font-size: ${fontSizes(`large`)};
  `}
`
const Currency = styled.span`
  font-size: ${fontSizes(`xxx_large`)};
  font-weight: 500;
  margin-left: 6px;
`

function ProductPrice ({ product, color = ``, cardVariant = false, rightVariant = false, rowVariant = false }) {
  const { t } = useTranslation()
  const { locale } = usePageContext()
  const { betterPrice, price, standardPrice, annualPurchaseOnly } = usePrice(product)
  const [animation, setAnimation] = useState(false)
  const lang = localeToLang(locale)

  if (!product) {
    return null
  }

  useEffect(() => {
    setAnimation(betterPrice)
  }, [betterPrice])

  const formatPrice = annualPurchaseOnly ? formatPriceAnnually : formatPriceMonthly
  const recurrence = annualPurchaseOnly
    ? t(`common:product.price_recurence_year`)
    : t(`common:product.price_recurence_month`)

  return (
    <Container cardVariant={cardVariant} rowVariant={rowVariant} color={color}>
      <PriceWrapperAnimated animation={animation} rightVariant={rightVariant}>
        <ContainerFlex data-testid={`product_${cardVariant ? `card_` : ``}${betterPrice ? `price` : `hidden_price`}`}>
          {lang === `nl` && <PerMonth>{`€ `}</PerMonth>}
          <Bold>{formatPrice(price)}</Bold>
          {lang !== `nl` && animation && rowVariant && <Currency>€</Currency>}
          <PerMonth rowVariant={rowVariant}>
            {lang !== `nl` && !rowVariant && <span>€</span>}
            {lang !== `nl` && (cardVariant ? `` : <span>&nbsp;TTC</span>)}
            <span>/{recurrence}</span>
            {lang === `nl` && (cardVariant ? `` : <span>&nbsp;(incl.BTW)</span>)}
          </PerMonth>
        </ContainerFlex>
      </PriceWrapperAnimated>
      <StandardPriceWrapperAnimated
        cardVariant={cardVariant}
        rightVariant={rightVariant}
        rowVariant={rowVariant}
        animation={animation}
      >
        <ContainerFlex data-testid={`product_${cardVariant ? `card_` : ``}${betterPrice ? `lower_price` : `price`}`}>
          {(cardVariant || rowVariant) && (
            <Instead animation={animation} rowVariant={rowVariant}>
              {t(`common:product.price_instead_of`)}
              &nbsp;
            </Instead>
          )}
          {lang === `nl` && <PerMonth>{`€ `}</PerMonth>}
          <AnimatedBold animation={animation} $color={color} cardVariant={cardVariant} rowVariant={rowVariant}>
            {formatPrice(standardPrice)}
            {lang !== `nl` && rowVariant && `€`}
          </AnimatedBold>
          {lang !== `nl` && !rowVariant && (
            <PerMonth>
              {cardVariant ? `€` : `€ TTC`}/{recurrence}
            </PerMonth>
          )}
          {lang === `nl` && !rowVariant && <PerMonth>{`/ ${recurrence} ${cardVariant ? `` : `(incl.BTW)`}`}</PerMonth>}
        </ContainerFlex>
      </StandardPriceWrapperAnimated>
    </Container>
  )
}

ProductPrice.propTypes = {
  color: Proptypes.string,
  product: Proptypes.object,
  cardVariant: Proptypes.bool,
  rightVariant: Proptypes.bool,
  rowVariant: Proptypes.bool,
}

export const ProductPriceMemo = memo(ProductPrice)
