import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { toPx } from "../structure/theme"

const Container = styled.span`
  display: inherit;

  svg {
    width: ${props => toPx(props.$size)};
    height: ${props => toPx(props.$size)};
    fill: ${props => props.$color};

    path {
      stroke: ${props => props.$color};
    }
  }
`

export default function UniversePicto ({ universe, size = 100, color }) {
  const { primaryColor, pictoSvg } = universe

  return <Container $color={color || primaryColor} $size={size} dangerouslySetInnerHTML={{ __html: pictoSvg }} />
}

UniversePicto.propTypes = {
  universe: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    pictoSvg: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
