import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON, HOUSINGTYPE_MIXTE } from "./product.utils"
import UniversePicto from "../universe/universe.picto"
import House from "../../components/pictos/house.component"
import Flat from "../../components/pictos/flat.component"
import breakpoint from "styled-components-breakpoint"
import { condition, useThemeContext } from "../structure/theme"
import { flexCenter } from "../structure/theme/styles.component"

function getPicto (name) {
  switch (name) {
    case HOUSINGTYPE_MAISON:
      return [House]
    case HOUSINGTYPE_APPARTEMENT:
      return [Flat]
    case HOUSINGTYPE_MIXTE:
      return [House, Flat]
  }
}

const PictoContainer = styled.span`
  ${flexCenter};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  height: 100%;
  width: 100%;
  padding: ${props => props.size / 7}px;
  position: relative;

  ${props =>
    props.hover &&
    `
    &:hover {
      &:after {
        height: ${props.size - (12 * props.size) / 100}px;
        left: ${(3 * props.size) / 100}px;
        top: ${(3 * props.size) / 100}px;
        width: ${props.size - (12 * props.size) / 100}px;
      }
    }
  `}

  &::after {
    border: ${props => (3 * props.size) / 100}px solid white;
    border-radius: 50%;
    content: "";
    height: ${props => props.size - 2 - (6 * props.size) / 100}px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: ${props => props.size - 2 - (6 * props.size) / 100}px;
    transition: height 0.1s, left 0.1s, top 0.1s, width 0.1s;
  }

  &:before {
    border: 3px solid white;
    border-radius: 50%;
    content: "";
    height: ${props => props.size}px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: ${props => props.size}px;
  }
`

const Mixed = styled.span`
  display: inline-block;
  max-width: 185px;
  overflow: hidden;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  margin-left: -${props => props.size / 5}px;
  &:first-child {
    margin-left: 0;
  }
`

const Container = styled.div`
  display: ${condition(`hideForResponsive`, `none`, `flex`)};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${condition(`card`, `center`, `left`)};

  ${breakpoint(`small`)`
    display: flex;
  `}
`

const Span = styled.span`
  height: ${props => props.styledHeight}px;
`

const Separator = styled.span`
  display: flex;
  margin: ${({ size, arrowSize }) => (size - arrowSize) / 2}px 10px 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${({ arrowSize }) => `${arrowSize / 2}px 0 ${arrowSize / 2}px ${arrowSize - 2}px`};
  border-color: transparent transparent transparent ${props => props.color || props.theme.colors.primary};
`

const HousingContainer = styled.span`
  display: inline-flex;
`

function ProductPicto ({
  housingType = ``,
  universes = [],
  hideForResponsive = false,
  size = 40,
  arrowSize = 15,
  hover = true,
  card = false,
  universesColor = false,
}) {
  const housingTypeArray = housingType ? getPicto(housingType) : []
  const {
    modules: {
      product: {
        picto: { universeColor: themeUniversePictoColor },
      },
    },
  } = useThemeContext()

  const universePictoColor = universesColor ? undefined : themeUniversePictoColor

  return (
    <Container card={card} hideForResponsive={hideForResponsive} data-testid={arguments[0][`data-testid`] || undefined}>
      {housingTypeArray.length > 0 && (
        <HousingContainer>
          {housingTypeArray.map(HousingType => (
            <Mixed size={size} key={HousingType} data-testid={`product-picto-housetype.${housingType}`}>
              <PictoContainer size={size} hover={hover}>
                <HousingType color="white" size="100%" />
              </PictoContainer>
            </Mixed>
          ))}
        </HousingContainer>
      )}
      {housingTypeArray.length > 0 && universes.length > 0 && <Separator arrowSize={arrowSize} size={size} />}
      <Span styledHeight={size}>
        {universes.map(universe => (
          <Mixed key={universe.slug} size={size} data-testid={`product-picto-universe.${universe.slug}`}>
            <UniversePicto universe={universe} size={size} color={universePictoColor} />
          </Mixed>
        ))}
      </Span>
    </Container>
  )
}

ProductPicto.propTypes = {
  hideForResponsive: PropTypes.bool,
  size: PropTypes.number,
  arrowSize: PropTypes.number,
  hover: PropTypes.bool,
  card: PropTypes.bool,
  universesColor: PropTypes.bool,
  housingType: PropTypes.oneOf([HOUSINGTYPE_MAISON, HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MIXTE]),
  universes: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  ),
}

export const ProductPictoMemo = memo(ProductPicto)
