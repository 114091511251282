import styled from "styled-components"
import { responsiveHamburger } from "../../navigation/menu/responsiveHamburger"
import cssArrow from "../../../components/pictos/cssArrow"
import { condition } from "../theme"

export const Arrow = styled.span.attrs(props => ({
  active: props.active !== undefined ? props.active : true,
  size: props.small ? `10px` : `16px`,
}))`
  display: flex;
  align-items: center;

  &::before {
    content: " ";
    width: 20px;
    height: 20px;
    background-image: ${props =>
      `url("${cssArrow({ color: props.foregroundColor, width: props.size, height: props.size })}")`};
    background-repeat: no-repeat;
    background-position: center center;
    transform: ${props => {
      if (props.left) {
        return `rotate(180deg);`
      }
      if (props.isOpened) {
        return props.down ? `rotate(-90deg);` : `rotate(90deg);`
      }
      if (props.down) {
        return `rotate(90deg);`
      }
    }};
    display: block;
    transition: transform 0.2s;
    font-weight: 900;
    opacity: ${condition(`active`, 1, 0.5)};
  }

  ${responsiveHamburger`
    &::before {
      display: ${props => props.hideForMedium && `none`};
    }
  `}
`
