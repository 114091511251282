import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Flat (props) {
  return (
    <Picto
      viewbox="0 0 10.42 14.52"
      d="M10.17.12A.51.51 0 0 0 9.82 0h-9a.78.78 0 0 0-.68.25.68.68 0 0 0-.14.53v13c0 .57.35.74.54.75h3.65a.25.25 0 0 0 .25-.25v-2.14L6 12.09v2.18a.25.25 0 0 0 .25.25h3.49a.64.64 0 0 0 .47-.18.84.84 0 0 0 .21-.55V.83a.88.88 0 0 0-.25-.71zM2.94 9s0 .2-.3.2H1.46s-.12 0-.12-.27V7.37s0-.2.12-.2h1.27s.21-.09.21.34zm0-2.89s0 .2-.3.2H1.46s-.12 0-.12-.27V4.48s0-.2.12-.2h1.27s.21-.09.21.34zm0-2.73s0 .2-.3.2H1.46s-.12 0-.12-.28V1.74s0-.2.12-.2h1.27s.21-.08.21.34zM6 9s0 .2-.3.2H4.48s-.12 0-.12-.27V7.37s0-.2.12-.2h1.28s.24-.09.24.34zm0-2.93s0 .2-.3.2H4.48s-.12 0-.12-.27V4.48s0-.2.12-.2h1.28s.24-.09.24.34zm0-2.73s0 .2-.3.2H4.48s-.12 0-.12-.28V1.74s0-.2.12-.2h1.28s.24-.08.24.34zM9.08 9s0 .2-.3.2H7.6s-.12 0-.12-.27V7.37s0-.2.12-.2h1.27s.21-.09.21.34zm0-2.89s0 .2-.3.2H7.6s-.12 0-.12-.27V4.48s0-.2.12-.2h1.27s.21-.09.21.34zm0-2.73s0 .2-.3.2H7.6s-.12 0-.12-.28V1.74s0-.2.12-.2h1.27s.21-.08.21.34z"
      {...props}
    />
  )
}

Flat.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
