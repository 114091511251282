import React from "react"
import PropTypes from "prop-types"
import ScrollerProductCard from "./scrollerProductCard.component"
import Scroller from "../../../components/scroller.component"

export default function ScrollerProduct ({ products }) {
  if (products.length <= 0) {
    return null
  }

  return (
    <Scroller>
      {products.map(product => (
        <ScrollerProductCard key={product.id} product={product} />
      ))}
    </Scroller>
  )
}

ScrollerProduct.propTypes = {
  products: PropTypes.array.isRequired,
}
