import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { condition, useBreakpoint } from "../modules/structure/theme"
import PropTypes from "prop-types"
import { Arrow as _Arrow } from "../modules/structure/theme/arrow.component"
import { useWindowResize } from "@homeserve/react-hooks"

const ScrollerWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoint(`medium`)`
    justify-content: ${condition(`center`, `center`, `space-between`)};
  `}
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const Arrow = styled(_Arrow)`
  margin: 0 20px;
`

export default function Scroller ({ children }) {
  const [activeArrows, setActiveArrows] = useState([false, true])
  const [hasScroll, setHasScroll] = useState(false)
  const scrollerWrapperRef = useRef(null)
  const size = useWindowResize()
  const windowBreakpoint = useBreakpoint()
  const isMobileOrTablet = windowBreakpoint === `small` || windowBreakpoint === `medium`
  const canUseArrow = hasScroll && !isMobileOrTablet

  // Set hasScroll
  useEffect(() => {
    const scrollWrapper = scrollerWrapperRef.current

    if (scrollWrapper) {
      const scrollWidth = scrollWrapper.scrollWidth
      const clientWidth = scrollWrapper.clientWidth

      setHasScroll(scrollWidth > clientWidth)
    }
  }, [size, scrollerWrapperRef.current])

  // Set activeArrows
  useEffect(() => {
    const scrollWrapper = scrollerWrapperRef.current

    if (scrollWrapper) {
      const onScroll = () => {
        setActiveArrows([
          scrollWrapper.scrollLeft > 0,
          scrollWrapper.scrollLeft < scrollWrapper.scrollWidth - scrollWrapper.clientWidth,
        ])
      }

      if (canUseArrow) {
        scrollWrapper.addEventListener(`scroll`, onScroll)
      }

      return () => {
        scrollWrapper.removeEventListener(`scroll`, onScroll)
      }
    }
  }, [size, canUseArrow, scrollerWrapperRef.current])

  function createSlide (direction) {
    return function slide () {
      const scrollWrapper = scrollerWrapperRef.current

      if (scrollWrapper) {
        scrollWrapper.scrollLeft += (scrollWrapper.scrollWidth / 2) * direction
      }
    }
  }

  return (
    <Wrapper>
      {canUseArrow && <Arrow left onClick={createSlide(-1)} active={activeArrows[0]} />}
      <ScrollerWrapper ref={scrollerWrapperRef} data-testid={arguments[0][`data-testid`] || undefined}>
        <InnerWrapper center={!canUseArrow}>{children}</InnerWrapper>
      </ScrollerWrapper>
      {canUseArrow && <Arrow onClick={createSlide(1)} active={activeArrows[1]} />}
    </Wrapper>
  )
}

Scroller.propTypes = {
  children: PropTypes.node.isRequired,
}
