import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors, fontSizes, useThemeContext } from "../../structure/theme"
import { ProductPictoMemo } from "../productPicto.component"
import { ProductPriceMemo } from "../productPrice.component"
import { ButtonLink } from "../../structure/theme/button.component"
import { useTranslation } from "../../translation/translate.component"
import { flexCenter } from "../../structure/theme/styles.component"

const Name = styled.h4`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: ${fontSizes(`large`)};
  font-weight: 900;
  line-height: 1.2;
  color: ${colors(`grey.g800`)};
`

const Container = styled.div`
  border: 2px solid ${colors(`grey.g650`)};
  width: 255px;
  min-width: 255px;
  border-radius: 10px;
  padding: 25px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;

  :not(:last-of-type) {
    margin-right: 15px;
  }
`

const Description = styled.div`
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  width: 100%;
  color: ${colors(`grey.g700`)};

  p {
    margin: 0;
  }
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const PriceWrapper = styled.div`
  ${flexCenter};
  padding-top: 10px;
`

const Link = styled(ButtonLink)`
  width: 100%;
  margin-top: 20px;
  text-transform: none;
  padding: 0;
`

export default function ScrollerProductCard ({ product }) {
  const { t } = useTranslation()
  const {
    colors: { primary },
  } = useThemeContext()

  return (
    <Container data-testid="product_card_home">
      <ProductPictoMemo data-testid="product_card_logo" card universes={product.universes} universesColor size={70} />
      <Name data-testid="product_card_name">{product.name}</Name>
      <Description data-testid="product_card_description" dangerouslySetInnerHTML={{ __html: product.hookTitle }} />
      <Bottom>
        <PriceWrapper>
          <ProductPriceMemo cardVariant product={product} color={primary} />
        </PriceWrapper>
        <Link data-testid="product_card_button" route="product" params={product}>
          {t(`common:product.scroller_button`)}
        </Link>
      </Bottom>
    </Container>
  )
}

ScrollerProductCard.propTypes = {
  product: PropTypes.object.isRequired,
}
